<template>
  <div class="container">
       <div v-if="!show">
      <div class="head effort doubt1">
<span class="black-text text-darken-3"><strong>Effort Analysis</strong></span>
<span><strong> / Doubt Report</strong></span>
<div class="dic divider"></div>
    </div>
  <div class="row card-panel card1">
<div class="col m3 s12 dtitle">
    <strong>Course</strong>
    <select class="browser-default dropd ">
      <option value="" disabled selected>-- Select --</option>
      <option value="1">JEE</option>
      <option value="2">9/10 CBSE</option>
      <option value="3">NEET</option>
      <option value="4">MHCET</option>
      <option value="5">11/12 CBSE</option>
    </select>
  </div>
        <div class="col m3 s12 dtitle">
    <strong>Batch</strong>
    <select class="browser-default dropd">
      <option value="" disabled selected>-- Select --</option>
      <option value="1">Batch 1</option>
      <option value="2">Batch 2</option>
      <option value="3">Batch 3</option>
      <option value="4">Batch 4</option>
      <option value="5">Batch 5</option>
    </select>
  </div>
     <div class="col m3 s12 dtitle">
    <strong>Date</strong>
    <select class="browser-default dropd">
      <option value="" disabled selected>Select Here</option>
      <option value="1">Last 3 hours</option>
      <option value="2">Today</option>
      <option value="3">Yesterday</option>
      <option value="4">Last Week</option>
      <option value="5">Custom</option>
    </select>
  </div>
  <div class="col m3 s12">
            <a class="waves-effect waves-light btn submitbutton">submit</a>
  </div>
    </div>
    <!-- <div class="row card-panel card2" v-if="show1">
  <div class="col m3 s12 dtitle">
    <strong>Start Date</strong>
    <input type="date" class="datepicker" placeholder="Select">
    </div>
   <div class="col m3 s12 dtitle">
    <strong>End Date</strong>
    <input type="date" class="datepicker" placeholder="Select">
    </div>
  <div class="col s3">

  </div>
  <div class="col s3">
  </div>
  </div> -->
  <div class="row card-panel card3">
  <div class="col m3 s12 dtitle">
    <strong>Subject</strong>
    <select class="browser-default dropd">
      <option value="" disabled selected>Select Here</option>
      <option value="1">Chemistry</option>
      <option value="2">Maths</option>
      <option value="3">Physics</option>
    </select>
    </div>
    <div class="col m3 s12 dtitle">
    <strong>Question Level</strong>
    <select class="browser-default dropd">
      <option value="" disabled selected>Select Here</option>
      <option value="1">Select All</option>
      <option value="2">Level 1</option>
      <option value="3">Level 2</option>
    </select>
  </div>
  <div class="col s3">

  </div>
  <div class="col s3">

  </div>
  </div>
  <div class="row">
      <div>
      <table class="qtbl centered highlight">
      <thead>
        <tr>
          <th>Name</th>
          <th>No.of Session</th>
          <th>Total Doubt Questions</th>
          <th>Correct Questions</th>
          <th>InCorrect Questions</th>
          <th>Skipped Questions</th>
         </tr>
      </thead>
      <tbody>
        <tr>
          <td><a @click="show=true">Sumit Agarwal</a></td>
          <td>05</td>
          <td>10</td>
          <td>04</td>
          <td>03</td>
          <td>03</td>
        </tr>
        <tr>
         <td><a @click="show=true">Amit Agarwal</a></td>
          <td>04</td>
          <td>12</td>
          <td>05</td>
          <td>05</td>
          <td>02</td>
        </tr>
      </tbody>
    </table>
    </div>
   </div>
 </div>
        <div v-if="show">
      <div class="head">
        <strong style="color:black">Effort Analysis</strong>/
        <strong><a class="doubt" @click="show=false" style="color:black">Doubt Report</a></strong>/
        <strong><a style="color:#642C90">Sumit Agarwal</a></strong>
      </div>
      <div class="dic divider"></div>
  <div class="row card-panel card4">
<div class="col m3 s12 dtitle">
    <strong>Course</strong>
    <select class="browser-default dropd">
      <option value="" disabled selected>-- Select --</option>
      <option value="1">JEE</option>
      <option value="2">9/10 CBSE</option>
      <option value="3">NEET</option>
      <option value="4">MHCET</option>
      <option value="5">11/12 CBSE</option>
    </select>
  </div>
        <div class="col m3 s12 dtitle">
    <strong>Batch</strong>
    <select class="browser-default dropd">
      <option value="" disabled selected>-- Select --</option>
      <option value="1">Batch 1</option>
      <option value="2">Batch 2</option>
      <option value="3">Batch 3</option>
      <option value="4">Batch 4</option>
      <option value="5">Batch 5</option>
    </select>
  </div>
     <div class="col m3 s12 dtitle">
    <strong>Date</strong>
    <select class="browser-default dropd">
      <option value="" disabled selected>Select Here</option>
      <option value="1">Last 3 hours</option>
      <option value="2">Today</option>
      <option value="3">Yesterday</option>
      <option value="4">Last Week</option>
      <option value="5">Custom</option>
    </select>
  </div>
  <div class="col m3 s12">
            <a class="waves-effect waves-light btn submitbutton">submit</a>
  </div>
    </div>
  <div class="row card-panel card5">
  <div class="col m3 s12 dtitle">
    <strong>Subject</strong>
    <select class="browser-default dropd">
      <option value="" disabled selected>Select Here</option>
      <option value="1">Chemistry</option>
      <option value="2">Maths</option>
      <option value="3">Physics</option>
    </select>
    </div>
    <div class="col m3 s12 dtitle">
    <strong>Question Level</strong>
    <select class = "browser-default dropd">
      <option value="" disabled selected>Select Here</option>
      <option value="1">Select All</option>
      <option value="2">Level 1</option>
      <option value="3">Level 2</option>
       <!-- <p>
      <label>
        <input type="checkbox" />
        <span>Level 1</span>
      </label>
    </p> -->
    </select>
  </div>
  <div class="col s3">

  </div>
  <div class="col s3">

  </div>
  </div>
      <table class="qtb centered highlight">
      <thead>
        <tr>
          <th>Course</th>
          <th>Subject</th>
          <th>Topic</th>
          <th>Chapter</th>
          <th>Concept</th>
          <th>Correct(Global Accuracy)</th>
          <th>Time(Global Correct Time)</th>
         <th>Action</th>
         </tr>
      </thead>
      <tbody>
        <tr>
          <td>JEE</td>
          <td>Physics</td>
          <td>Mechanics</td>
          <td>Elasticity</td>
          <td>Thermal Stresses and Strains</td>
          <td>Correct(59%)</td>
          <td>2:25(3:22)</td>
          <td><a href="/#">View</a>
          <button class="waves-effect waves-light bttn btn">Done</button></td>
        </tr>
        <tr>
          <td>JEE</td>
          <td>Physics</td>
          <td>Mechanics</td>
          <td>Gravitation</td>
          <td>Kepler's Law</td>
          <td>InCorrect(39%)</td>
          <td>4:30(1:19)</td>
          <td><a href="/#">View</a>
          <button class="waves-effect waves-light bttn btn">Done</button></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      show1: true,
    };
  },
};
</script>

<style scoped>
table.qtl {
    border-collapse: separate;
    border-spacing: 0 10px;
  }
  table.qtl thead tr {
    background-color: #6a2f85;
    color: #fff;
  }
  table.qtl thead tr th {
    border-radius: 0;
  }
  table.qtl thead tr th:first-child,
  table.qtl tbody tr td:first-child {
    border-radius: 5px 0 0 5px;
  }
  table.qtl tbody tr {
    cursor: pointer;
    background-color: #fff;
    font-size: 14px;
  }
.dropd {
    border-radius: 5px;
    width: 268px;
    height: 36px;
  }
.doubt{
    cursor: pointer;
  }
.head {
    margin-top: 19px;
    font-size: 25px;
    color: #642C90;
  }
.effort{
  margin-top: 19px;
  left:132px;
  font-size:25px;
}
.doubt1{
  margin-top: 19px;
  left: 209px;
  font-size:25px;
  color:#642C90;
}
.dic{
    margin-top: 18.5px;
    border-radius: 1px solid;
    margin-bottom: 24.5px;
    width:1176px;
}
.card1{
    left:500px;
    width:1176px;
    border-radius: 5px;
    margin-top: 24px;
    background: #FFFFFF;
box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
}
/* .card2{
    left:500px;
    width:1176px;
    height: 111px;
    border-radius: 5px;
    margin-top: 13px;
    background: #FFFFFF;
box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
} */
.card3{
    margin-top: 13px;
    height: 100px;
    width:1176px;
    border-radius: 5px;
    background: #FFFFFF;
box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
}
.card4{
    width:1176px;
    height: 100px;
    border-radius: 5px;
    margin-top: 24px;
    background: #FFFFFF;
box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
}
.card5{
    margin-top: 13px;
    height: 100px;
    width:1176px;
    border-radius: 5px;
    background: #FFFFFF;
box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
}
.submitbutton{
    background: linear-gradient(90.2deg, #FF9421 0.16%, #FBAD26 98.41%);
    border-radius: 5px;
    font-size: 14px;
    margin-left: 41px;
    margin-top: 22px;
    width:144px;
    height: 38px;
}
 .bttn{
    color: white;
    background: linear-gradient(90.2deg, #37BC16 0.16%, #37BC16 98.41%);
    border-radius: 5px;
    font-size:14px;
    margin-left: 13px;
    width: 82px;
    height: 30px;
}
 table.qtbl {
    border-collapse: separate;
    border-spacing: 0 10px;
    width: 1176px;
  }
  table.qtbl thead tr {
    background-color: #6a2f85;
    color: #fff;
  }
  table.qtbl thead tr th {
    border-radius: 0;
  }
  table.qtbl thead tr th:first-child,
  table.qtbl tbody tr td:first-child {
    border-radius: 5px 0 0 5px;
  }
  table.qtbl tbody tr {
    cursor: pointer;
    background-color: #fff;
    font-size: 14px;
  }
  .dtitle{
   font-size:18px;
    color: #333333;
}
  table.qtb {
    border-collapse: separate;
    border-spacing: 0 10px;
    width:1176px;
  }
  table.qtb thead tr {
    background-color: #6a2f85;
    color: #fff;
  }
  table.qtb thead tr th {
    border-radius: 0;
  }
  table.qtb thead tr th:first-child,
  table.qtb tbody tr td:first-child {
    border-radius: 5px 0 0 5px;
  }
  table.qtb tbody tr {
    cursor: pointer;
    background-color: #fff;
    font-size: 14px;
  }
</style>
